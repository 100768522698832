<template>
  <el-button
    :disabled="time>0">
    <span v-show="time===0">发送验证码</span>
    <span v-show="time>0">已发送({{time}})</span>
  </el-button>
</template>

<script>
import { TIME_MSG_INTERVAL } from '@/config';
import { sendTelCode } from '@/global/globalMethods';


export default {
  name: 'ShopPcBtnSendMsg',

  data() {
    return {
      time: 0,
    };
  },

  methods: {
    getTelCode(params) {
      if (!params.tel) return Promise.reject();
      this.time = TIME_MSG_INTERVAL;
      return sendTelCode(params.tel, 'user').then((res) => {
        const time = setInterval(() => {
          this.time -= 1;
          if (this.time === 0) {
            clearInterval(time);
          }
        }, 1000);
        return res;
      }, () => {
        this.time = 0;
      });
    },
  },
};
</script>
