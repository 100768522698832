<template>
  <login-bg :shoDownUrl="!isShell">
    <div class="login-container">
      <el-form :model="input" :rules="rules" ref="input" class="login-form login-findPassword-main">
        <el-form-item class="login-tit">
          <h5 class="login-tit-text flexbox flex-align-center">
            <img src="@/assets/img/login/logo.png" class="login-logo">
            <span class="login-title">合作伙伴后台系统</span>
          </h5>
        </el-form-item>
        <div>
          <!-- <el-form-item>
            <el-checkbox v-model="input.remember" style="margin-left:3.5px;">记住登录</el-checkbox> -->
            <!-- <el-checkbox v-model="input.queryPlatformCode">查询平台编码</el-checkbox> -->
          <!-- </el-form-item> -->
          <el-form-item prop="tel">
            <el-input type="text" class="login-tel login-input"
              placeholder="手机号" @change="isAllowLogin('input')" v-model="input.tel">
              <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-personal"></i>
              <span slot="prefix" class="line"></span> -->
              <img slot="prefix" src="@/assets/img/login/my.png"
                style="width:11.91px;height:14.11px" />
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="platCode">
            <el-input type="text" class="login-tel login-input" placeholder="平台编码(加入多个平台必填)"
              @change="isAllowLogin('input')" v-model="input.platCode">
              <i slot="prefix" class="s-pc-iconfont s-pc-icon-pingtaibianma"></i>
              <span slot="prefix" class="line"></span>
            </el-input>
          </el-form-item> -->
          <el-form-item prop="image_code" class="login-img-code login-img-code-other">
            <el-input type="text" class="login-code login-input" placeholder="图片验证码"
              @change="isAllowLogin('input')" @keydown.enter.native="login('input')"
              v-model="input.image_code">
              <img slot="prefix" src="@/assets/img/login/pic.png"
                style="width:12.54px;height:12.23px" />
            </el-input>
            <img class="verify_code verify_code-other" :src="codeImg" @click="getImageVerify"
              title="看不清？点击换一张" />
          </el-form-item>
          <el-form-item prop="tel_code" class="login-phone-code">
            <el-input type="text" class="login-code login-input" placeholder="短信验证码"
              @change="isAllowLogin('input')" v-model="input.tel_code">
              <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-phone"></i>
              <span slot="prefix" class="line"></span> -->
              <img slot="prefix" src="@/assets/img/login/code.png"
                style="width:12.54px;height:13.04px" />
            </el-input>
            <btn-send-msg class="pointer login-send login-send-other" @click.native="getTelCode"
              ref="btnSendMsg">
            </btn-send-msg>
          </el-form-item>
          <el-form-item prop="new_password">
            <el-input type="password" show-password class="login-pass login-input"
              placeholder="设置密码" @change="isAllowLogin('input')" v-model="input.new_password">
              <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-password"></i>
              <span slot="prefix" class="line"></span> -->
              <img slot="prefix" src="@/assets/img/login/pwd.png"
                style="width:12.42px;height:13.64px" />
            </el-input>
          </el-form-item>
          <el-form-item prop="re_password">
            <el-input type="password" class="login-pass login-input" placeholder="重复密码"
              @change="isAllowLogin('input')" @keydown.enter.native="getPassword('input')"
              v-model="input.re_password">
              <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-password"></i>
              <span slot="prefix" class="line"></span> -->
              <img slot="prefix" src="@/assets/img/login/pwd.png"
                style="width:12.42px;height:13.64px" />
            </el-input>
          </el-form-item>
          <el-form-item class="login-btn" style="margin-bottom: 0px; margin-top:33px;">
            <el-button @click="getPassword('input')" :class="loginState">重置密码</el-button>
            <!-- <router-link class="pointer back-login" to="/login">返回登录</router-link> -->
          </el-form-item>
        </div>
        <el-form-item class="login-btn" style="margin-bottom: 0px;">
          <!-- <el-button @click="getPassword('input')" :class="loginState">查询</el-button> -->
          <router-link class="pointer back-login" :to="`/login?spread=${$route.query.spread}`">返回登录</router-link>
        </el-form-item>
      </el-form>
    </div>
  </login-bg>
</template>

<script>
import {
  $sg_getImageVerify,
  $sp_getBackPassword,
  $sg_checkShell,
  // $sp_getSpreadCountByTel,
  // $sp_getSpreadTokenBySpreadTel,
} from '@/api';
import { deepClone } from '@base/utils';

import LoginBg from '@/components/login/LoginBg.vue';
import BtnSendMsg from '@/components/common/BtnSendMsg.vue';

export default {
  name: 'FindPassword',

  components: {
    LoginBg,
    BtnSendMsg,
  },

  data() {
    const validateTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.input.new_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validateTelCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入图片验证码'));
      } else {
        callback();
      }
    };
    return {
      isShell: true,
      codeImg: '',
      loginState: 'refuse-login',
      tel_code_time: 0,
      input: {
        tel: '', /* 手机号 */
        vid: '', /* 验证码vid */
        image_code: '', /* 图像验证码 */
        tel_code: '', /* 电话号码 */
        login_terminal: 32, /* 8站点管理员,4 平台，2店铺，1消费者 */
        terminal: 32, /* 8站点管理员,4 平台，2店铺，1消费者 */
        new_password: '', /* 新密码 */
        re_password: '', /* 确认密码 */
        platCode: '', // 平台编码
      },
      sendTime: 0,
      rules: {
        tel: [
          { validator: validateTel, trigger: 'blur' },
        ],
        new_password: [
          { validator: validatePass, trigger: 'blur' },
        ],
        re_password: [
          { validator: validateRePass, trigger: 'blur' },
        ],
        tel_code: [
          { validator: validateTelCode, trigger: 'blur' },
        ],
        image_code: [
          { validator: validateCode, trigger: 'blur' },
        ],
      },
      type: 1,
      isMorePlat: false,
    };
  },

  created() {
    this.input.platCode = this.$route.query.spread;
    this.getImageVerify();
    // this.checkShell();
  },

  methods: {
    clearValidate() {
      this.$refs.input.clearValidate();
    },
    qeuryPlatCode() {
      // $sp_getSpreadTokenBySpreadTel({ tel: this.input.tel }).then((res) => {
      //   console.log(res);
      //   this.input.platCode = res.spread_token || '该手机号未注册';
      //   if (res.token) {
      //     this.isMorePlat = true;
      //   }
      // });
    },
    getSpreadCountByTel() {
      // $sp_getSpreadCountByTel({ tel: this.input.tel }).then((res) => {
      //   console.log(res);
      //   this.isMorePlat = res !== 0;
      // });
    },
    checkShell() {
      $sg_checkShell().then((is) => {
        this.isShell = is;
      });
    },
    getImageVerify() { // 获取图片验证码
      $sg_getImageVerify().then((resp) => {
        this.codeImg = resp.image;
        this.input.vid = resp.vid;
      });
    },
    getTelCode() { // 发送短信验证码
      const sendData = this.input;
      sendData.mess_type = 2;
      this.$refs.btnSendMsg.getTelCode(sendData, 2);
    },
    getPassword(formName) { // 找回密码
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const sendData = deepClone(this.input);
          sendData.spread_token = this.$route.query.spread;
          sendData.appName = 'user';
          $sp_getBackPassword(sendData).then(() => {
            this.$router.push({
              path: '/login',
              query: {
                spread: this.$route.query.spread,
              },
            });
          }).catch(() => { this.getImageVerify(); });
        }
      });
    },
    isAllowLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginState = 'allow-login';
        } else {
          this.loginState = 'refuse-login';
        }
      });
    },
  },
};
</script>
