<template>
  <login-bg :shoDownUrl="!isShell">
    <div class="login-container login-page">
      <el-form :model="input" :rules="rules" ref="input" class="login-form">
        <el-form-item class="login-tit">
          <h5 class="login-tit-text flexbox flex-align-center">
            <img src="@/assets/img/login/logo.png" class="login-logo">
            <span class="login-title">合作伙伴后台系统</span>
          </h5>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input type="text" class="login-tel login-input"
            placeholder="请输入您的登录账号" v-model="input.phone">
            <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-personal"></i> -->
            <img slot="prefix" src="@/assets/img/login/my.png"
              style="width:11.91px;height:14.11px" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" show-password class="login-pass login-input"
            placeholder="请输入您的登录密码" v-model="input.password">
            <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-password"></i> -->
            <!-- <span slot="prefix" class="line"></span> -->
            <img slot="prefix" src="@/assets/img/login/pwd.png"
              style="width:12.42px;height:13.64px" />
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="platCode" v-if="isMorePlat">
        <el-input
          type="text"
          class="login-tel login-input"
          placeholder="平台编码(加入多个平台必填)"
          @change="isAllowLogin('input')"
          v-model="input.platCode">
          <i slot="prefix" class="s-pc-iconfont s-pc-icon-pingtaibianma"></i> -->
        <!-- <span slot="prefix" class="line"></span> -->
        <!-- </el-input>
      </el-form-item> -->
        <el-form-item class="login-img-code" prop="image_code">
          <el-input type="text" class="login-code login-input" placeholder="请输入您的验证码"
            @keydown.enter.native="login('input')"
            v-model="input.image_code">
            <!-- <i slot="prefix" class="s-pc-iconfont s-pc-icon-picture"></i>
          <span slot="prefix" class="line"></span> -->
            <img slot="prefix" src="@/assets/img/login/pic.png"
              style="width:12.54px;height:12.23px" />
          </el-input>
          <img class="verify_code" :src="codeImg" @click="getImageVerify" title="看不清？点击换一张" />
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="input.remember">记住登录</el-checkbox>
          <router-link class="clo6 pointer right" title="登录问题" :to="`/findPassword?spread=${$route.query.spread}`">
            <span style="color:#AAA">登录遇到问题？</span>
          </router-link>
        </el-form-item>
        <el-form-item class="login-btn" style="margin-top:92px;">
          <el-button @click="login('input')" :class="loginState">
            登&nbsp;&nbsp;&nbsp;录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </login-bg>
</template>

<script>
import {
  $sg_getImageVerify,
  $sg_checkShellAndOem,
  $sp_login,
  $sg_getSpreadQrLoginUrl,
  $sg_abnormalOrderReminder,
} from '@/api';
// import { setUserToken, } from '@/global/tokenManager';

import LoginBg from '@/components/login/LoginBg.vue';

import {
  cookie,
  deepClone,
// setUserPermission
} from '@base/utils';

import { setUserToken } from '@/global/tokenManager';

// import { setSpreadToken, creatIframeAndGetWindow, getSpreadToken } from '@/config';

export default {
  name: 'Login',
  data() {
    const validateTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    };
    return {
      isShell: true,
      codeImg: '',
      showLoginQrCode: true,
      loginLink: '',
      loginState: 'refuse-login',
      input: {
        phone: cookie('o2o_last_login_name') || '', /* 手机号 */
        vid: '', /* 验证码vid */
        image_code: '', /* 图像验证码 */
        password: '', /* 密码 大于等于6位 */
        login_terminal: 32, /* 8站点管理员,4 平台，2店铺，1消费者 */
        remember: true, /* 记住登陆 */
        platCode: '', // 平台编码
      },
      rules: {
        phone: [
          { validator: validateTel, trigger: 'blur' },
        ],
        password: [
          { validator: validatePass, trigger: 'blur' },
        ],
        image_code: [
          { validator: validateCode, trigger: 'blur' },
        ],
      },
      isMorePlat: false,
      isOem: true,
    };
  },
  components: {
    loginBg: LoginBg,
  },
  created() {
    this.input.platCode = this.$route.query.spread;
    // if (!this.$route.query.spread) {
    //   this.$message.error('页面参数缺失请联系管理员获取正确的登录链接');
    // }

    // if (getSpreadToken()) {
    //   this.$router.push('/admin');
    //   return;
    // }
    this.getImageVerify();
    this.getSpreadQrLoginUrl();
    this.checkShell();
  },

  methods: {
    checkShell() {
      $sg_checkShellAndOem().then((resp) => {
        this.isShell = resp.is_shell;
        this.isOem = resp.is_oem || this.IsDeploy;
      });
    },
    getSpreadQrLoginUrl() {
      return $sg_getSpreadQrLoginUrl().then((res) => {
        this.loginLink = res;
      });
    },
    getImageVerify() {
      $sg_getImageVerify().then((resp) => {
        this.codeImg = resp.image;
        this.input.vid = resp.vid;
        this.input.image_code = '';
      });
    },
    login() { // 登录
      // if (!this.$route.query.spread) {
      //   this.$message.error('页面参数缺失请联系管理员获取正确的登录链接');
      // }
      this.$refs.input.validate((valid) => {
        if (valid) {
          const sendData = deepClone(this.input);
          sendData.remember = sendData.remember ? 1 : 0;
          sendData.spread_token = this.$route.query.spread;
          $sp_login(sendData).then((resp) => {
            localStorage.setItem('errorStatus', resp.erorStatus || true);
            cookie('o2o_last_login_name', sendData.phone, { path: '/' });
            // const token = `Bearer ${resp.token}`;
            // let authority = '[]';
            // 长时间未修改密码需要更改密码
            // if (resp.password_need_update) {
            //   sessionStorage.setItem('userLoginPwdIsNoEdit', '1');
            // }
            // if (resp.authority !== undefined && resp.authority !== -1) {
            //   authority = resp.authority;
            // }
            // if (this.loginType === 1) {
            //   sessionStorage.setItem('userLoginPwdIsStrong', resp.password_strength ? 0 : 1);
            // }
            setUserToken(resp.token);
            // setSpreadToken(token);
            // setUserPermission(authority);
            this.$router.push('/main/agentIndex');
            $sg_abnormalOrderReminder({ orderType: 2 }).then((res) => {
              localStorage.setItem('errorStatus', res);
              this.$router.push('/main/agentIndex');
            });
          }).catch((resp) => {
            console.log('resp', resp);
            this.getImageVerify();
            this.$message(resp.msg);
          });
        }
      });
    },
    // 查询异常订单
    // queryErrorOrder() {
    //   $sg_abnormalOrderReminder({ orderType: 2 }).then((res) => {
    //     console.log(res);
    //   });  
    // },
    isAllowLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginState = 'allow-login';
        } else {
          this.loginState = 'refuse-login';
        }
      });
    },
  },
};
</script>
<style lang='scss'>
@import "@/style/var.scss";
.login-page {
  @media screen and (min-width: 501px) {
    .login-tit {
      padding-bottom: 20px;
    }
  }
}
@media screen and (max-width: 501px) {
  #app {
    width: 100%;
    min-height: 100vh;
    min-width: initial !important;
  }
  .login-bg-container {
    height: 100%;
    .login-container {
      background: #fff;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .title {
      color: #01a8b7;
      font-size: 30px;
      margin: 0;
      position: absolute;
      top: 40px;
      left: 0;
      font-weight: 800;
      background: none;
      z-index: 1;
      text-align: center;
      width: 100%;
    }
    .login-form {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      .qrcode-iframe {
        margin: 0 auto;
        display: block;
      }
    }
    .el-form-item__content {
      padding: 0 20px;
      box-sizing: border-box;
      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0;
      }
      .el-form-item__error {
        left: 20px;
      }
      .login-code {
        width: calc(100% - 112px);
        display: inline-block;
      }
      img {
        width: 108px;
        height: 36px;
        vertical-align: middle;
      }
      .refuse-login,
      .allow-login {
        width: 100%;
        margin: 0 auto;
        color: #fff;
        border-color: transparent;
        background-color: #01a8b7;
        border-radius: 5px;
        height: 40px;
        span {
          font-size: 16px;
        }
      }
      .login-send {
        width: 108px;
      }
    }
  }
  .down-text {
    display: none;
  }
}
</style>
